.u14-slideshowNavigation {

	// &__arrow {

	// }


	&__arrows {
		@include u14-container();

		display: none;
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		// bottom: $slideshowTriggersMargin + $slideshowTriggerDiameter;
		pointer-events: none;

		@include media($columnsBreakpoint) {
			display: flex;
		}

		> * {
			pointer-events: all;
		}
	}


	&__triggers {
		display: flex;
		justify-content: center;
		margin-top: $slideshowTriggersMargin;
		line-height: 0;
	}


	&__trigger {
		flex-grow: 0;
		padding: $base; //base 3 ...
	}
}
