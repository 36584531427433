.u14-preview {
	.u14-block--colSpan12 & {
		@include media($columnsBreakpoint) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}


	&__category {
		@include font(category);
		order: 1;

		.u14-preview__title + & {
			margin-bottom: $base3;
		}
	}


	&__description {
		* + & {
			margin-top: $base3;

			@include media($columnsBreakpoint) {
				margin-top: $base;
			}
		}
	}


	&__header {
		display: flex;
		flex-direction: column;


		.u14-block--vAlignJustified & {
			flex-grow: 1;
		}
	}


	&__info {
		display: flex;
		flex-direction: column;

		.u14-block--vAlignBegin &,
		.u14-block--vAlignJustified & {
			justify-content: flex-start;
		}

		.u14-block--vAlignEnd & {
			justify-content: flex-end;
		}

		.u14-block--vAlignCenter & {
			justify-content: center;
		}


		.u14-block--colSpan12 & {
			@include media($columnsBreakpoint) {
				width: staticColumnSize(1, 3, $gutter);
			}
		}

		* + & {
			margin-top: $base3;
		}
	}


	&__link {
		@include u14-call2actions;
		margin-top: 1em;
	}


	&__subtitle {
		order: 3;
	}


	&__title {
		@include font(title);
		order: 2;

		* + & {
			margin-top: $base3;
		}
	}


	&__titleLink {
		@include u14-link;
	}
}
