.u14-videoPlayer {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;


	&__embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;


		> iframe {
			border: 0;
			height: 100%;
			width: 100%;
		}
	}
}
