.u14-ugc {
	// User Generated Content Style

	a {
		@include u14-call2actions;
	}


	ol {
		list-style-position: inside;
	}

	p + p {
		margin-top: 1em;
		margin-bottom: $base3;
	}


	ul {
		li::before {
			content: '– ';
		}

		.u14-block--featuresList &,
		&.features,
		&.facilities {
			@include font(ugsFeatures);
			padding-left: $base3;
			position: relative;

			&::before {
				background-color: $colorHighlight;
				bottom: 0.4em;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				top: 0.4em;
				width: 1px;
			}

			li {
				&::before {
					content: unset;
				}
			}
		}
	}
}
