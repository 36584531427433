// refer to _dynamic-columns.scss for documentation

// $size is the width in number of columns
// $total is the max number of columns supported by the grid
// $hSpace is the gutter
@function dynamicColumnPush($size, $total, $hSpace, $opposite: false) {
	// width: calc(#{100% / $cols * $size} - #{$hSpace * $size} + #{$hSpace * ($size - 1)});
	// width: calc(#{100% / $cols * $size} - #{$hSpace});
	@return if($hSpace != 0, calc(#{100% * $size / $total} #{if($opposite == false, unquote(' + ') #{$hSpace}, unquote(''))}), (100% * $size / $total));
}
