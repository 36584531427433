.u14-slide {
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	flex-shrink: 0;
	// padding-right: $slideGap;
	position: relative;
	width: 100%;


	&--colorSchemeDefault {
		background-color: $colorBgBase;
		color: $colorBase;
	}

	&--colorSchemeInverted {
		background-color: $colorBgInverted;
		color: $colorInverted;
	}

	&--colorSchemeAlternative {
		background-color: $colorBgAlternative;
	}

	.u14-slideshow--animationTypeFade & {
		@include ease(opacity visibility, $slideshowFadeDuration);
		@include fadeOut();
		z-index: 1;

		@for $index from 0 through ($slideshowMaxItems - 1) {
			&:nth-child(#{$index + 1}) {
				transform: translateX(#{-100% * $index});
			}
		}

		&.u14-js-current {
			@include fadeIn();
			z-index: 2;
		}
	}

	.u14-slideshow--animationTypeSlide .u14-slideshow__items.u14-js-animate & {
		@include ease(transform, $slideshowFadeDuration);
	}

	.u14-slideshow--animationTypeSlide.u14-slideshow--circular & {
		$balancedLimit: ceil($slideshowMaxItems / 2);

		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through $balancedLimit {
				&:nth-child(#{$index + 1})[data-u14-balanced-position="#{$position}"] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-u14-balanced-position="-#{$position}"] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}

	.u14-slideshow--animationTypeSlide:not(.u14-slideshow--circular) & {
		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through ($slideshowMaxItems - 1) {
				&:nth-child(#{$index + 1})[data-u14-position="#{$position}"] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-u14-position="-#{$position}"] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}


	&__category {
		@include font(category);
	}


	&__container {
		// background-color: $colorBaseTextOnImageContrast;
		// color: currentColor;
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: center;
		order: 2;
		padding: $blockVerticalSpacing 0;
		position: relative;
		text-decoration: none;
		width: 100%;
		z-index: 1;

		// making the text selectable only when you are touching the text elements
		> * {
			.u14-slide:active & {
				user-select: none;
			}

			.u14-slide:active &:active {
				user-select: auto;
			}
		}

		// .u14-block--hPositionBegin > & {
		// 	align-items: flex-start;
		// }

		// .u14-block--hPositionCenter > & {
		// 	align-items: center;
		// }

		// .u14-block--hPositionEnd > & {
		// 	align-items: flex-end;
		// }

		// .u14-block--vAlignBegin > & {
		// 	justify-content: flex-start;
		// }

		// .u14-block--vAlignEnd > & {
		// 	justify-content: flex-end;
		// }

		// .u14-block--vAlignCenter > & {
		// 	justify-content: center;
		// }

		// .u14-block--vAlignJustified > & {
		// 	justify-content: flex-start;
		// 	// the content will decide who is taking space and who is not
		// }

		// .u14-block--minHeightBg > & {
		// 	transform: translateX(-100%);
		// }
	}


	&__content {
		@include u14-container();

		text-align: center;
	}


	&__description {
		@include font(sliderText);
	}


	&__figure {
		height: auto;
		flex-grow: 0;
		flex-shrink: 0;
		// opacity: $teaserBgOpacity;
		order: 1;
		// padding-right: $slideGap;
		width: 100%;
		z-index: 0;

		.u14-slideshow:not(.u14-slideshow--minHeightBg) & {
			bottom: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
		}

		// #{'.u14-block' + $modifier} .u14-block__container:not(.u14-block__container--emptyLink) + & {
		.u14-slide__container + & {
			opacity: $teaserBgOpacity;
		}
	}


	&__link {
		@include u14-call2actions;
	}


	&__subtitle {
		@include font(sliderSubTitle);

		.u14-slide__title + & {
			@include media($columnsBreakpoint) {
				margin-top: $base;
			}
		}
	}


	&__title {
		@include font(sliderTitle);
	}
}
