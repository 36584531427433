.u14-calendar {
	// we would like to expand the days list on the sides
	// in order to fit more with the design (numbers on the sides touching the container border)
	@mixin expandMargins() {
		// we expand of half tile length - 0.5em on each side
		margin-left: calc(#{50% * (1 - 7 / 6)} + 0.6em);
		margin-right: calc(#{50% * (1 - 7 / 6)} + 0.6em);
	}


	@mixin monthTransition {
		@include ease(transform, $duration4);

		&.u14-js-enter {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			will-change: transform;
			z-index: 2;

			&.u14-js-dirBackward {
				transform: translateX(-100%);
			}

			&.u14-js-dirForward {
				transform: translateX(100%);
			}
		}

		&.u14-js-leave {
			position: relative;
			will-change: transform;
			z-index: 1;

			&.u14-js-dirBackward {
				transform: translateX(100%);
			}

			&.u14-js-dirForward {
				transform: translateX(-100%);
			}
		}
	}


	@include font(calendar);
	border-width: 1px;
	border-style: solid;
	line-height: 1;
	padding: $base2 0;
	position: relative;

	.u14-booking__check & {
		// background-color: $colorBgModal;
		display: none;
		pointer-events: all;
		visibility: hidden;

		@include media($enoughHeightBreakpoint) {
			display: block;
		}

		@include media($bookingBreakpoint) {
			visibility: inherit;
		}
	}

	.u14-booking__check.u14-js-active & {
		visibility: inherit;
	}


	&__day {
		font-weight: normal;
		position: relative;
		text-align: center;
		width: dynamicColumnSize(1, 7, 0);
		// border: 1px solid black;

		// &.u14-js-selectionHighlight:not(:hover) {
		&.u14-js-selectionHighlight {
			color: $colorBgAlternative;
		}

		&[data-u14-calendar-is-today="true"] {
			font-weight: bold;
		}


		&:hover {
			z-index: 32;
		}


		&::before {
			@include ease(opacity visibility, $duration2);
			@include fadeOut();
			content: '';
			background-color: $colorBgAlternative;
			bottom: 0;
			left: 0;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}


		&.u14-js-selection::before {
			@include fadeIn();
		}

		&.u14-js-selectionBegin::before {
			left: calc(50% - #{$base * 1.5});
			border-top-left-radius: ($base * 1.5);
			border-bottom-left-radius: ($base * 1.5);
		}

		&.u14-js-selectionEnd::before {
			right: calc(50% - #{$base * 1.5});
			border-top-right-radius: ($base * 1.5);
			border-bottom-right-radius: ($base * 1.5);
		}


		&::after {
			@include ease(opacity visibility, $duration2);
			@include fadeOut();
			content: '';
			background-color: $colorAlternative;
			border-radius: 50%;
			bottom: 0;
			left: calc(50% - #{$base * 1.5});
			position: absolute;
			top: 0;
			right: calc(50% - #{$base * 1.5});
			z-index: 2;
		}

		&.u14-js-selectionHighlight::after,
		&.u14-js-selectionHighlight::after {
			@include fadeIn();
		}
	}


	&__dayButton {
		color: currentColor;
		display: flex;
		justify-content: center;
		align-items: center;
		height: $base3;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: 100%;
		z-index: 3;


		&:disabled {
			opacity: 0.2;
		}

		&::before {
			@include ease(opacity, $duration2);
			content: '';
			background-color: $colorBgBase;
			border-radius: 50%;
			border-style: solid;
			border-width: 1px;
			height: $base3;
			left: 50%;
			opacity: 0;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: $base3;
			z-index: 0;
		}

		// &.u14-js-selected:not(:disabled)::before {
		// 	opacity: 1;
		// }
		@include onHover() {
			color: $colorAlternative;
		}
		@include onHover('&:not(:disabled)', '::before') {
			opacity: 1;
		}

		// &:not(:disabled):hover::before {
		// }
	}


	&__days {
		// $hSpace: false, $vSpace: false, $hSpacePos: left, $vSpacePos: top,
		@include dynamicColumns($hSpace: 0, $vSpace: $baseHalf, $vSpacePos: bottom);
		@include monthTransition();
		padding: 0 $base2;
	}


	&__daysContainer {
		// @include expandMargins();
		overflow: hidden;
		position: relative;
	}


	&__dayValue {
		display: block;
		margin: auto;
		position: relative;
		z-index: 1;
	}


	&__header {
		@include font(calendar);
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		line-height: 1;
		margin-bottom: $base2;
		padding: 0 $base * 2.5;
		text-align: center;

		html.no-js & {
			justify-content: center;
		}
	}


	&__month {
		@include monthTransition();
		text-align: center;
		width: 100%;
		font-size: inherit;
	}


	&__monthContainer {
		flex-grow: 1;
		margin: 0 $baseHalf;
		overflow: hidden;
		position: relative;
	}


	&__monthLink {
		display: inline-block;
		flex-grow: 0;
		// height: $base2;
		line-height: 0;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: auto;

		&--prev {
			transform: rotate(180deg);
		}

		html.no-js & {
			display: none;
		}


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}


	&__weekDay {
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		width: staticColumnSize(1, 7, 0);
	}


	&__weekDays {
		@include font(calendarDay);
		@include staticColumns();
		// @include expandMargins();
		padding: 0 $base2;
		margin-bottom: $base2;
	}
}
