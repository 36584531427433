.u14-field {
	@include font(field);
	width: 100%;

	&--disabled {
		opacity: $opacityInputDisabled;
	}

	.u14-trackers__groupActions &,
	.u14-trackers__trackerActions & {
		display: inline-flex;
		width: auto;
	}

	&--bookingCheck {
		width: 50%;

		@include media($bookingBreakpoint) {
			width: 100%;
		}

		.u14-booking__check--out & {
			margin-left: 50%;

			@include media($bookingBreakpoint) {
				margin-left: 0;
			}
		}
	}

	.u14-form & {
		width: dynamicColumnSize(1, 1, $gutter);

		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(1, 2, $gutter);
		}

		&--full {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(1, 1, $gutter);
			}
		}

		&--alone {
			@include media($columnsBreakpoint) {
				margin-right: dynamicColumnPush(1, 2, $gutter, true);
			}
		}
	}

	&--newsletterEmail {
		order: 1;
	}

	&--newsletterAcceptDataPolicy {
		order: 3;
	}

	.u14-form__separator + & {
		margin-top: $base4;
	}

	.u14-form__separator + &:not(&--full):not(&--alone) + &:not(&--full):not(&--alone) {
		@include media($columnsBreakpoint) {
			margin-top: $base4;
		}
	}

	// nested
	.u14-field__fields > & {
		width: dynamicColumnSize(1, 1, $gutter);
	}

	// nested
	.u14-field__fields--horizontal > & {
		@include media($columnsBreakpoint) {
			display: inline-block;
			width: auto;
			margin-left: $base3;
			margin-top: 0;
		}
	}

	.u14-field__fields--grid > & {
		@include media($columnsBreakpoint) {
			width: dynamicColumnSize(1, 3, $gutter);
		}

		&--double {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(2, 3, $gutter);
			}
		}
	}

	.u14-field--checkboxes &,
	.u14-field--radios & {
		margin-top: 0;
	}

	&__content {
		margin-left: -$base3;

		.u14-field--bookingCheck & {
			margin-left: 0;
			position: relative;
		}

		.u14-field--group > & {
			margin-left: 0;
		}
	}

	&__description {
		margin-left: $base3;

		&:not(&--extended) {
			@include font(fieldDescription);
			margin-top: $baseHalf;
		}

		.u14-field--password &,
		.u14-field--select &,
		.u14-field--text &,
		.u14-field--textarea & {
			margin-top: $baseHalf;
		}

		.u14-field--checkbox &,
		.u14-field--radio & {
			margin-left: $base6;
		}
	}

	div.mce_inline_error, // code generated by Mailchimp
	&__errors {
		@include font(fieldError);
		color: $colorError;
		margin-top: $baseHalf;
	}

	&__fields {
		@include dynamicColumns($hSpace: $gutter, $vSpace: $fieldsVSpace, $hSpacePos: left, $vSpacePos: top);
		position: relative;

		.u14-field--checkboxes &,
		.u14-field--radios & {
			margin-top: 0;
		}

		&--horizontal {
			@include media($columnsBreakpoint) {
				font-size: 0;
				display: block;
				margin-top: 0;
				margin-left: -$base3;
			}
		}
	}

	&__info {
		color: $colorInput;
		display: block;

		&--strong {
			color: $colorBase;
		}

		.u14-field--radio &,
		.u14-field--checkbox & {
			margin-left: $base3;
		}

		.u14-field__label + & {
			margin-top: -$base;

			@include media($columnsBreakpoint) {
				margin-top: 0;
			}
		}

		.u14-field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				padding: $inputPadding 0;
				margin-left: 0;
				width: dynamicColumnSize(1, 6, $gutter);
			}

			&--compact {
				@include media($columnsBreakpoint) {
					width: dynamicColumnSize(2, 12, $gutter);
				}
			}

			&--right {
				@include media($columnsBreakpoint) {
					text-align: right;
				}
			}

			&--first {
				color: $colorBase;
				margin-left: $base3;
				flex-grow: 1;
			}
		}
	}

	&__input {
		@include font(field);
		background-color: $colorBgInput;
		color: $colorInput;
		text-align: center;

		&:focus {
			outline-color: currentColor;
			outline-style: solid;
			outline-width: $inputBorderSize;
		}

		&::placeholder {
			color: currentColor;
		}

		&--bookingCheck {
			height: 100%;
			left: 0;
			opacity: 0;
			pointer-events: all;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
			-webkit-appearance: none;

			@include media($enoughHeightBreakpoint) {
				@include visuallyHidden();
				pointer-events: none;
				visibility: hidden;
			}
		}

		&--password,
		&--text {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			padding: $inputPadding;
			width: 100%;

			.u14-field--invalid & {
				border-color: $colorError;
			}

			.u14-field--full & {
				@include media($columnsBreakpoint) {
					max-width: staticColumnSize($formFieldFullColumns, 12, $base2);
				}
			}

			.u14-field--full .u14-field__fields & {
				@include media($columnsBreakpoint) {
					max-width: none;
				}
			}

			.u14-field__label--radioText & {
				padding: $inputNestedPadding $inputPadding;
			}

			.u14-field--search & {
				max-width: none;
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
			}

			.u14-field--newsletterEmail & {
				background-color: transparent;
			}

			.u14-field--bookingAmount & {
				background-color: transparent;
				border-radius: 0;
				text-align: center;
				// -moz-appearance: textfield;
				-webkit-appearance: textfield;

				::placeholder {
					text-align: center;
				}

				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		&--textarea {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			min-height: $base2 + getFontSize(base) * getLineHeight(base) * 4;
			padding: $base;
			width: 100%;

			.u14-field--full & {
				@include media($columnsBreakpoint) {
					max-width: staticColumnSize($formFieldFullColumns, 12, $base2);
				}
			}

			.u14-field--invalid & {
				border-color: $colorError;
			}
		}

		&--select {
			background-color: transparent;
			border: $inputBorderSize solid $colorInputBorder;
			// needed because on OSX to reset the appearance is not enough
			border-radius: 0;
			min-width: $buttonMinWidth;
			padding: $inputPadding $base2 $inputPadding $inputPadding;
			position: relative;
			width: 100%;
			z-index: 2;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			.u14-field--invalid & {
				border-color: $colorError;
			}
		}

		&--checkbox,
		&--radio,
		&--toggler {
			@include visuallyHidden();
		}
	}

	&__inputWrapper {
		margin-left: $base3;
		position: relative;

		.u14-field--bookingCheck & {
			height: 100%;
			left: 0;
			margin: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		.u14-field--select & {
			background-color: $colorBgBase;
		}

		&--extended {
			@include media($columnsBreakpoint) {
				@include dynamicColumns($hSpace: $gutter, $hSpacePos: right);
			}
		}

		.u14-field--horizontal & {
			@include media($columnsBreakpoint) {
				display: inline-block;
			}
		}

		.u14-field--full.u14-field--select & {
			@include media($columnsBreakpoint) {
				max-width: staticColumnSize($formFieldFullColumns, 12, $base2, -$base2);
			}
		}

		.u14-field--search & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include media($columnsBreakpoint) {
				max-width: staticColumnSize($formFieldFullColumns, 12, $base2, -$base2);
			}
		}

		.u14-field--radioText & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
		}

		.u14-field--toggler & {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
		}
	}

	&__label {
		@mixin replaceInput($circle: true) {
			&::before {
				border: $inputBorderSize solid $colorInputBorder;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize;
				left: -$base3;
				position: absolute;
				top: $base;
				vertical-align: middle;
				width: $radioOuterSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				background-color: currentColor;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioInnerSize;
				left: -$base3 + ($radioOuterSize - $radioInnerSize) / 2;
				opacity: 0;
				position: absolute;
				top: $base + ($radioOuterSize - $radioInnerSize) / 2;
				vertical-align: middle;
				width: $radioInnerSize;
			}

			input:focus + & {
				outline-color: currentColor;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		@include font(field);
		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		color: $colorInput;
		display: block;
		margin-left: $base3;
		padding: $inputPadding 0;
		position: relative;

		&--strong {
			color: $colorBase;
		}

		&--important {
			@include font(baseBigger);
			font-weight: strong;
		}

		.u14-field--bookingAmount & {
			@include visuallyHidden();
		}

		.u14-field--bookingCheck & {
			@include font(field);
			border-color: $colorInputBorder;
			border-style: solid;
			border-width: $buttonBorderSize;
			cursor: pointer;
			display: block;
			margin: 0;
			padding: $inputPadding 0;
			pointer-events: none;
			position: relative;
			text-align: center;
			width: 100%;
			z-index: 2;

			@include media($enoughHeightBreakpoint) {
				border-bottom-width: 0;
			}

			&:hover {
				color: $colorHighlight;
			}

			.u14-booking__check.u14-js-active & {
				@include media($enoughHeightBreakpoint) {
					background-color: $colorBgAlternative;
				}

				@include media($bookingBreakpoint) {
					background-color: transparent;
				}
			}

			@include media($enoughHeightBreakpoint) {
				pointer-events: all;
			}

			@include media($bookingBreakpoint) {
				pointer-events: none;
			}

			.u14-booking__check--out & {
				border-left-width: 0;

				@include media($bookingBreakpoint) {
					border-left-width: $buttonBorderSize;
				}
			}
		}

		.u14-field--group > .u14-field__content > & {
			margin-left: 0;
		}

		.u14-field--password,
		.u14-field--text & {
			display: block;
		}

		&--checkbox {
			input[type='checkbox'] + & {
				@include replaceInput($circle: false);
			}

			input[type='checkbox']:checked + &::after {
				opacity: 1;
			}

			// input[type="checkbox"][disabled="disabled"] + &::before {
			// 	display: none;
			// }

			input[disabled] + &::before {
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				outline-color: currentColor;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		&--radio {
			input[type='radio'] + & {
				@include replaceInput($circle: true);
			}

			input[type='radio']:checked + &::after {
				opacity: 1;
			}

			input[disabled] + &::before {
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				outline-color: currentColor;
				outline-width: $inputBorderSize;
				outline-style: solid;
			}

			.u14-field--radioText & {
				bottom: 0;
				flex-grow: 0;
				left: 0; // $base3;
				padding-right: 0;
				padding-left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			.u14-field__inputWrapper--extended & {
				@include media($columnsBreakpoint) {
					flex-grow: 1;
				}
			}
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}

		.u14-field__inputWrapper--extended & {
			@include media($columnsBreakpoint) {
				width: dynamicColumnSize(6, 12, $gutter, -$base3);
			}
		}

		.u14-field--toggler &--inputLabel {
			@include ease(opacity, $duration);
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			align-self: center;
			padding-right: $base * 4.5;
			position: relative;
			z-index: 1;
			padding-top: 0;
			padding-bottom: 0;
		}

		.u14-field--toggler .u14-field__input:last-of-type:focus ~ &--inputLabel:first-of-type,
		.u14-field--toggler .u14-field__input:first-of-type:focus ~ &--inputLabel:last-of-type {
			outline: 1px solid currentColor;
			outline-offset: 2px;
		}

		.u14-field--toggler .u14-field__input:last-of-type:checked ~ &--inputLabel:first-of-type,
		.u14-field--toggler .u14-field__input:first-of-type:checked ~ &--inputLabel:last-of-type {
			z-index: 2;
		}
	}

	&__labelValue {
		.u14-field--required > .u14-field__content > .u14-field__label &::after,
		.u14-field--required > .u14-field__content > .u14-field__inputWrapper > .u14-field__label &::after {
			content: '*';
		}

		.u14-field--toggler .u14-field__input:last-of-type:checked ~ .u14-field__label:first-of-type &,
		.u14-field--toggler .u14-field__input:first-of-type:checked ~ .u14-field__label:last-of-type & {
			visibility: hidden;
		}
	}

	&__selectIcon {
		color: $colorInput;
		position: absolute;
		right: $baseHalf;
		top: 50%;
		height: $base;
		width: $base;
		text-align: center;
		transform: translateY(-50%) rotate(90deg);
		z-index: 1;

		[data-type='svg'] {
			width: auto;
			height: 100%;
		}
	}

	&__text {
		margin-left: $base3;

		&--info {
			margin-top: $base3;
			margin-left: 0;
			margin-bottom: $base3;
		}
	}

	&__link {
		display: block;
		font-weight: bold;
		margin-left: $base3;
		padding-bottom: $base1;
		text-decoration: none;

		@include onHover {
			color: $colorBase;
		}
	}

	&__radioToggler {
		@include ease(background-color, $duration);
		background-color: $colorBase;
		border-radius: ($base);
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		justify-self: end;
		align-self: center;
		cursor: pointer;
		height: $base2;
		margin: 0 1px 0 0;
		order: 3;
		position: relative;
		user-select: none;
		width: $base * 3.4;
		z-index: 3;
		position: relative;
		pointer-events: none;

		@include onHover() {
			// background-color: $colorButtonHoverBg;
		}

		&::before {
			@include ease(transform, $duration);
			background-color: $colorBgBase;
			border-radius: 50%;
			content: '';
			height: $base * 1.6;
			left: 50%;
			margin-left: -($base * 0.8);
			margin-top: -($base * 0.8);
			position: absolute;
			top: 50%;
			width: $base * 1.6;
		}

		.u14-field__input:nth-of-type(1):checked ~ &::before {
			transform: translateX(#{-$base * 0.7});
		}

		.u14-field__input:nth-of-type(2):checked ~ &::before {
			transform: translateX(#{$base * 0.7});
		}
	}
}
