.u14-booking {
	color: $colorAlternative;


	&__amount {
		width: staticColumnSize(4, 8, $base);

		@include media($bookingBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			order: -1;
			width: staticColumnSize(2, 12, $gutter);
		}
	}


	&__check {
		pointer-events: none;
		position: relative;
		width: staticColumnSize(8, 8, $base);
		z-index: 1;

		&.u14-js-active {
			z-index: 2;
			// background-color: red;
		}


		@include media($bookingBreakpoint) {
			pointer-events: all;
			width: staticColumnSize(3, 12, $gutter);
		}

		&--out {
			left: 0;
			position: absolute;
			top: 0;

			@include media($bookingBreakpoint) {
				left: auto;
				position: static;
				top: auto;
			}
		}
	}


	&__container {
		@include staticColumns($base3);
		position: relative;

		@include media($bookingBreakpoint) {
			@include staticColumns();
		}
	}


	&__inputBox {
		position: relative;
	}


	&__submit {
		width: staticColumnSize(4, 8, $base);

		@include media($bookingBreakpoint) {
			margin-right: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(2, 12, $gutter);
		}
	}
}
