.u14-form {
	position: relative;


	.u14-newsletterSignup & {
		@include media($newsletterSignupBreakpoint) {
			width: staticColumnSize(1, 2, $gutter);
		}
	}


	&__buttons {
		// this removes the space between inline-blocks / inline-flex elements.
		// the proper font size is set in the children
		font-size: 0;
		margin-left: -$base2;
		margin-bottom: -$base2;

		* + & {
			margin-top: $base3;
		}

		.u14-form__separator + & {
			margin-top: $base4;
		}
	}


	&__fields {
		@include dynamicColumns($hSpace: $gutter, $vSpace: $fieldsVSpace, $hSpacePos: left, $vSpacePos: top);
		position: relative;
	}


	&__separator {
		background-color: currentColor;
		border: 0;
		height: $boxBorderSize;
		margin-bottom: 0;
		display: block;
		width: 100%;

		.u14-form__fields & {
			width: dynamicColumnSize(9, 9, $gutter);
		}

		* + & {
			margin-top: $base4;
		}
	}


	&__text {
		.u14-form__fields & {
			width: dynamicColumnSize(1, 1, $gutter);
		}

		.u14-form__buttons & {
			@include font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.u14-form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.u14-form > * + & {
			margin-top: $base3;
		}

		.u14-form__separator + & {
			margin-top: $base4;
		}
	}


	&__title {
		@include font(baseBigger);
		font-weight: normal;
	}
}
