.u14-video {
	@mixin hideVideoCoverWhenReady() {
		@include ease(opacity visibility, $duration2);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.u14-video.u14-js-loading & {
			@include fadeOut();
		}

		.u14-video.u14-js-loaded & {
			display: none;
		}
	}


	&__caption {
		@include font(caption);
		margin-top: $base;

		.u14-blocks:not(.u14-blocks--nested) > .u14-block--video.u14-block--hSizeFull & {
			@include u14-container();
		}
	}


	&__content {
		@include aspectRatio($videoRatio);
		position: relative;
	}


	&__icon {
		height: $base4;
		left: 50%;
		position: absolute;
		top: 50%;
		width: $base4;
		margin-left: -$base2;
		margin-top: -$base2;


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__img {
		@include hideVideoCoverWhenReady();
		@include objectFit(cover);
	}


	&__link {
		@include hideVideoCoverWhenReady();
	}
}
