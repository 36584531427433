.u14-links {
	display: flex;
	margin-left: -$base2; // horizontal gutter
	margin-top: ($base2 - $base2);	// real marginTop - vertical gutter
	flex-wrap: wrap;

	.u14-block--hAlignBegin & {
		justify-content: flex-start;
	}

	.u14-block--hAlignEnd & {
		justify-content: flex-end;
	}

	.u14-block--hAlignCenter & {
		justify-content: center;
	}


	&__item {
		margin-left: $base2;
		margin-top: $base2;
	}
}
