.u14-text {
	@include font(base);

	.u14-block--textSizeBigger & {
		@include font(baseBigger);
	}

	.u14-block--teaser.u14-block--vAlignJustified & {
		flex-grow: 1;
	}

	.u14-block--extraLeftMargin & {
		@include media($columnsBreakpoint) {
			padding-left: $base4;
		}
	}
}
