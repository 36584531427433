.u14-trackers {
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	left: 0;
	min-height: 100vh;
	pointer-events: none;
	position: fixed;
	right: 0;
	z-index: 20;

	&.u14-js-fixed {
		position: fixed;
	}

	&.u14-js-beforeActive {
		position: relative;
	}

	&.u14-js-hidden {
		display: none;
	}

	&::before {
		@include ease(opacity, visibility, $duration4);
		@include fadeOut();
		background-color: $colorBgModalCover;
		bottom: 0;
		content: '';
		left: 0;
		pointer-events: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 0;
	}

	&.u14-js-modal::before,
	&.u14-js-beforeActive::before {
		@include fadeIn();
	}

	&__actions {
		@include staticColumns($base);

		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}

	&__container {
		@include ease(height, $duration4);
		background-color: $colorBgBase;
		// border-top: 1px solid currentColor;
		color: $colorBase;
		flex-grow: 0;
		flex-shrink: 0;
		overflow: hidden;
		padding: $base2 0;
		pointer-events: auto;
		position: relative;
		border-top: 1px solid $colorBgTrackerGroupHeader;
	}

	&__details {
		@include u14-container();
		@include ease(opacity visibility, $duration2);
		left: 0;
		// padding: $base 0 0 $base;
		padding-top: $base3;
		position: relative;
		right: 0;
		top: 0;

		&.u14-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__detailsActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(8, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}

	&__group {
		@include ease(height, $duration4);
		overflow: hidden;
		position: relative;

		& + & {
			margin-top: $base4;
		}
	}

	&__groupActions {
		@include media($columnsBreakpoint) {
			// margin-left: staticColumnPush(1, 12, $gutter);
			// width: staticColumnSize(4, 12, $gutter);
			margin-left: $base2;
			display: flex;
			justify-content: flex-end;
		}
	}

	&__groupDetails {
		@include ease(opacity visibility, $duration2);
		bottom: 0;
		left: 0;
		position: relative;
		right: 0;
		padding: $base2;

		&.u14-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__groupInfo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $base $base2;

		@include media($columnsBreakpoint) {
			// width: staticColumnSize(7, 12, $gutter);
		}
	}

	&__groupSummary {
		background-color: $colorBgTrackerGroupHeader;

		@include media($columnsBreakpoint) {
			// @include staticColumns();
			// align-items: center;
		}
	}

	&__groupTitle {
		flex-grow: 1;
	}

	&__groups {
		margin-bottom: $base3;
	}

	&__info {
		@include font(base);

		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}

	&__summary {
		@include u14-container();
		@include media($columnsBreakpoint) {
			@include staticColumns();
			align-items: center;
		}
	}

	&__tracker {
		@include media($columnsBreakpoint) {
			@include staticColumns();
		}

		& + & {
			border-top: 1px solid currentColor;
			margin-top: $base;
			padding-top: $base;
		}
	}

	&__trackers {
		padding-top: $base2;
	}

	&__trackerActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
			display: flex;
			justify-content: flex-end;
		}
	}

	&__trackerInfo {
		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}
}
