.u14-slideshow {
	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		touch-action: pan-y;
		width: 100%;
	}


	&__itemsContainer {
		display: flex;
		width: 100%;
		position: relative;


		@include media($columnsBreakpoint) {
			// just an example, it can be smaller
			width: 100%;
		}

		.u14-slideshow--minHeightFixed & {
			min-height: $slideMinHeight;
		}

		.u14-slideshow--minHeightFixedRatio & {
			@include minAspectRatio($slideRatio);
		}
	}
}
