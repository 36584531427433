.u14-mapBox {
	height: $mapHeightSmall;
	position: relative;
	width: 100%;

	@include media($columnsBreakpoint) {
		height: $mapHeightLarge;
	}


	&__loading {
		@include ease(opacity visibility, $duration2);
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		.u14-block--map.u14-block--colorSchemeDefault & {
			background-color: $colorBgBase;
			color: $colorBase;
		}

		.u14-block--map.u14-block--colorSchemeInverted &,
		.u14-block--layout.u14-block--colorSchemeInverted .u14-block--map.u14-block--colorSchemeDefault & {
			background-color: $colorBgInverted;
			color: $colorInverted;
		}

		.u14-block--map.u14-block--colorSchemeAlternative &,
		.u14-block--layout.u14-block--colorSchemeAlternative .u14-block--map.u14-block--colorSchemeDefault & {
			background-color: $colorBgAlternative;
		}


		.u14-googleMap.u14-js-loaded ~ & {
			@include fadeOut();
		}
	}


	&__loadingLabel {
		@include font(base);
	}
}
